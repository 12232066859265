.container {
  display: flex;
  width: 100%;
  background: inherit;
  justify-content: center;
}

.buttonWrapper {
  display: flex;
  align-items: flex-end;
  background: inherit;
}

.buttonActive:focus-within {
  background-color: #FEAB31 !important;
  outline: none;
  border-radius: 12px;
  padding: 18px;
  color: white !important
}

.textButton {
  font-weight: 600;
  cursor: pointer;
  font-size: 16px;
}


.textButton:focus-within {
  color: white !important;
  font-weight: 600;
  cursor: pointer;
  font-size: 16px;
}

.buttonActive {
  background-color: #E5F3FF;
  outline: none;
  border-radius: 12px;
  padding: 18px;
}


.monthContainer {
  display: flex;
  flex-direction: column;
  cursor: pointer;
  padding: 2px;
  margin: 2px;
}

.monthYearLabel {
  z-index: 3;
  font-size: 22px;
  font-weight: bold;
  position: sticky;
  top: 10px;
  text-align: center;
  margin-left: 24px;
  color: #010101;
  margin-bottom: 20px;
}

.dateDayItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  margin: 0 0 0 5px;
  width: 45px;
  height: 49px;
  flex-shrink: 0;
}

.dateLabel {
  font-size: 12px;
}

.button {
  border: none;
  text-decoration: none;
  cursor: pointer;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  color: rgb(152, 152, 152);
  background-color: #e5f3ff;
  font-size: 20px;
  font-weight: bold;
  flex-shrink: 0;
  display: flex;
  border-radius: 100px;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin-bottom: 13px;
  z-index: 2;
}
.dateList {
  display: flex;
  overflow-x: scroll;
  scrollbar-width: none;
  margin: 2px 0 2px -40px;
  -webkit-overflow-scrolling: touch;
}

.dateList::-webkit-scrollbar {
  -webkit-appearance: none;
  display: none;
}

.daysContainer {
  display: flex;
  z-index: 1;
  margin-top: 10px;
  margin-left: 40px;

}

.dayLabel {
  font-size: 16px;
}

.selected {
  width: 50px;
  height: 50px;
  color: #666;
  border: solid 1px #FEAB31;
  border-radius: 30px;
  background-color: #FEAB31;
}

.today {
  font-weight: bold;
  width: 50px;
  height: 50px;

}
